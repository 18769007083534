import googleLibPhoneNumber from "google-libphonenumber";
const PNF = googleLibPhoneNumber.PhoneNumberFormat;
import dayjs from "dayjs";

export function randomUUID() {
  let u = Date.now().toString(16) + Math.random().toString(16) + "0".repeat(16);
  let result = [
    u.substr(0, 8),
    u.substr(8, 4),
    "4000-8" + u.substr(13, 3),
    u.substr(16, 12),
  ].join("-");
  return result;
}

export function scrollToId(id) {
  if (!id) return;
  const anchorEl = document.getElementById(id);
  if (anchorEl) {
    anchorEl.scrollIntoView();
  }
}

export function scrollIntoView(el, animate = true) {
  if (!el) return;
  el.scrollIntoView({ behavior: animate && "smooth" });
}

export const isSafari = /^((?!chrome|android).)*safari/i.test(
  navigator.userAgent
);

export function validateEmail(mail) {
  if (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(mail)) {
    return true;
  }
  return false;
}

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export function formatCurrency(value) {
  return currencyFormatter?.format(value);
}

export function formatJobDate(value) {
  return dayjs(value).format("dddd, MMMM D [at] h:mm A");
}

const phoneUtil = googleLibPhoneNumber?.PhoneNumberUtil.getInstance();
export function validatePhoneNumber(number) {
  try {
    return phoneUtil?.isValidNumberForRegion(
      phoneUtil?.parse(number, "US"),
      "US"
    );
  } catch {
    return false;
  }
}

export function getRawPhoneNumber(number) {
  const raw_number = phoneUtil?.parseAndKeepRawInput(number, "US")
  return phoneUtil?.format(raw_number, PNF.E164)
}
