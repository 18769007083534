export const routes = {
  ChooseProduct: "/choose-product/",
  ScheduleTank: "/schedule-tank/",
  ScheduleTankless: "/schedule-tankless/",
  Summary: "/summary/",
  Success: "/success/",

  Tank: "tank",
  Tankless: "tankless",
  Category: {
    Tank: {
      WaterHeaterType: "water-heater-type",
      PowerConnectType: "power-connect-type",
      WaterHeaterWorking: "water-heater-working",
      HomeType: "home-type",
      WaterHeaterLocation: "water-heater-location",
      WaterHeaterSize: "water-heater-size",
    }
  }
}

function convertSearches(params) {
  const searchKeys = Object.keys(params).filter(key => params[key])
  let search = null
  if (searchKeys.length > 0) {
    search = "?"
    for (let i = 0; i < searchKeys.length; i++) {
      const key = searchKeys[i]
      search += `${key}=${encodeURIComponent(params[key])}`
      if (i !== searchKeys.length - 1) search += "&"
    }
  }
  return search ?? ""
}

export function redirectTo({ route, category = null, searches = {} }) {
  const pathname = category ? `${route}/${category}/` : route
  const search = convertSearches(searches)
  return { pathname, search }
}

export function routeToMe({ pathname = null, searches = {} }) {
  const prevSearches = parseSearchParams(new URLSearchParams(window.location.search))
  return {
    pathname: pathname ?? window.location.pathname,
    search: convertSearches({ ...prevSearches, ...searches })
  }
}

/** @param {URLSearchParams} searchParams */
export function parseSearchParams(searchParams) {
  const result = {}
  for (const entry of searchParams.entries()) {
    const [key, value] = entry
    result[key] = value
  }
  return result
}
