import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Layout, Stack } from "@shopify/polaris";
import { ProAppFrame, ProAppPage } from "@src/components";
import { ProAppLoginPhoneNumberCard } from "./login-number-form";
import { ProAppLoginVerificationCard } from "./login-verify-form";
import logo from "@src/assets/images/booking/logo.svg";
import { validatePhoneNumber, getRawPhoneNumber } from "@src/utils";
import { Api, useAuth } from "@src/core";
import "./styles.scss";

export function LoginPage() {
  const navigate = useNavigate();

  const { userLogin, isAuthenticated } = useAuth();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberErr, setPhoneNumberErr] = useState("");
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [code, setCode] = useState("");
  const [codeErr, setCodeErr] = useState("");
  const [loadingForSend, setLoadingForSend] = useState(false);
  const [loadingForLogin, setLoadingForLogin] = useState(false);

  useEffect(() => {
    if (isAuthenticated) navigate("/job-list/");
  }, [isAuthenticated]);

  const onChangePhoneNumber = useCallback(
    (value) => {
      setPhoneNumber(value);
      setPhoneNumberErr("");
    },
    [phoneNumber, phoneNumberErr]
  );

  const onClickSendSMS = useCallback(async () => {
    setCodeErr("");
    if (!validatePhoneNumber(phoneNumber)) {
      setPhoneNumberErr("Phone number format is incorrect");
      return;
    }
    setLoadingForSend(true);
    try {
      await Api.post(
        "pro/auth/send-sms/" + getRawPhoneNumber(phoneNumber) + "/"
      );
      setIsCodeSent(true);
    } catch (error) {
      if (error.response.status == 400) {
        setPhoneNumberErr("We can't find your number");
      } else {
        setPhoneNumberErr("There is an issue to send sms. Sorry for it.");
      }
    }
    setLoadingForSend(false);
  }, [setLoadingForSend, phoneNumber, isCodeSent, phoneNumberErr, codeErr]);

  const onClickLogin = useCallback(async () => {
    setLoadingForLogin(true);
    try {
      const res = await Api.post("pro/auth/login/", {
        number: phoneNumber,
        code: code,
      });
      userLogin(res.data);
      navigate("/job-list/");
    } catch (error) {
      setCodeErr("Verification code is incorrect");
    }
    setLoadingForLogin(false);
  }, [loadingForLogin, phoneNumber, code, codeErr]);

  const onChangeCode = useCallback(
    (value) => {
      setCode(value);
      setCodeErr("");
    },
    [code, codeErr]
  );

  const loginContent = (
    <Layout>
      <Layout.Section fullWidth>
        <Stack distribution="center">
          <Stack.Item>
            <img
              className="logo"
              src={logo}
              alt="Homebreeze"
              width="240px"
              height="auto"
              style={{
                objectFit: "cover",
                objectPosition: "center",
                paddingTop: "11.75rem",
                paddingBottom: "1rem",
              }}
            />
          </Stack.Item>
        </Stack>
      </Layout.Section>

      {!isCodeSent && (
        <Layout.Section>
          <ProAppLoginPhoneNumberCard
            phoneNumber={phoneNumber}
            phoneNumerErr={phoneNumberErr}
            onChangePhoneNumber={onChangePhoneNumber}
            onClickNext={onClickSendSMS}
            isLoading={loadingForSend}
          />
        </Layout.Section>
      )}

      {isCodeSent && (
        <Layout.Section>
          <ProAppLoginVerificationCard
            code={code}
            codeErr={codeErr}
            onChangeCode={onChangeCode}
            onClickLogin={onClickLogin}
            onClickResendCode={onClickSendSMS}
            isLoading={loadingForLogin}
          />
        </Layout.Section>
      )}
    </Layout>
  );

  const loginPage = <ProAppPage>{loginContent}</ProAppPage>;

  return (
    <ProAppFrame hideTopBar hideNavigation>
      {loginPage}
    </ProAppFrame>
  );
}
