import React from "react";

import "./styles.scss";
import iconSampleProductAvatar from "@src/assets/images/sample/sample-product-avatar.png";

import { Badge, Tag, PrimaryButton, Button } from "@src/components";

import { Product } from "@src/core";
import { formatCurrency } from "@src/utils/index";

/**
 * @typedef {{className: string, product: Product, selectProduct: Function}} ProductListItemProps
 * @param {ProductListItemProps} param0
 */
export function ProductListItem({
  className = "",
  product,
  selectProduct
}) {
  const handleSelectProduct = (productId) => {
    selectProduct(productId);
  };

  return (
    <div className={`${className} product-list-item`}>
      {product.isPopular && <Badge>Popular Choice</Badge>}
      <div className="avatar">
        <img
          src={
            product.product.product_image
              ? product.product.product_image
              : iconSampleProductAvatar
          }
          alt=""
        />
      </div>
      <div className="info">
        <div className="name">{product.product.title}</div>
        <div className="description">{product.product.description}</div>
        <div className="item-list">
          {(product.items ?? []).map((item, index) => (
            <div key={index} className="item">
              <span>{item.name}</span>
              <span>{item.description}</span>
            </div>
          ))}
        </div>
        <div className="tags">
          <div>What's included</div>
          <div className="tag-list">
            {(product.tags ?? []).map((tag, index) => (
              <Tag key={index}>{tag}</Tag>
            ))}
          </div>
        </div>
      </div>
      <div className="actions">
        <div className="price-value">
          {formatCurrency(product.price.finalPrice)}*
        </div>
        <div className="price-description">
          *{product.price.description}{" "}
          {product.price.learnMore && (
            <a href={product.price.learnMore} target="_blank">
              Learn more
            </a>
          )}
        </div>
        <PrimaryButton
          fullWidth
          onClick={() => {
            handleSelectProduct(product.id);
          }}
          size="large"
        >
          Select product
        </PrimaryButton>
        <div className="pay-upon-installation">
          Pay upon installation
        </div>
      </div>
    </div>
  );
}
