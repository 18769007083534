export class DOMHelper {
  static deviceName(windowWidth) {
    if (windowWidth >= 992) return 'desktop'
    if (windowWidth >= 577) return 'tablet'
    return 'mobile'
  }

  /**
   * @param {HTMLElement} el
   * @returns {DOMRect}
   */
  static position(el) {
    return el?.getBoundingClientRect()
  }

  static calcX(el) {
    if (!el) return 0
    let result = el.getBoundingClientRect().left
    if (typeof result === "string") {
      result = result.replace('px', '')
      result = parseInt(result)
    }
    // console.log(result)
    return result
  }

  static calcWidth(el) {
    if (!el) return 0
    let result = el.getBoundingClientRect().width
    if (typeof result === "string") {
      result = result.replace('px', '')
      result = parseInt(result)
    }
    // console.log(result)
    return result
  }

  static calcY(el) {
    if (!el) return 0
    // let result = el.getBoundingClientRect().top
    let result = window.innerHeight - this.calcHeight(el)
    if (typeof result === "string") {
      result = result.replace('px', '')
      result = parseInt(result)
    }
    // console.log("y", result)
    return result
  }


  static calcHeight(el) {
    if (!el) return 0
    let result = el.getBoundingClientRect().height
    if (typeof result === "string") {
      result = result.replace('px', '')
      result = parseInt(result)
    }
    // console.log("height", result)
    return result
  }

  static matches(el, selector) {
    if (!el || !selector) return false
    return el.matches(selector)
  }

  static closest(el, selector) {
    if (!el || !selector) return null
    return el.closest(selector)
  }

  /**
   * @param {HTMLElement} el
   * @param {boolean} animate
   */
  static scrollIntoView(el, animate = true) {
    if (!el) return
    el.scrollIntoView({
      behavior: animate ? 'smooth' : 'auto',
      block: 'center',
      inline: 'center'
    })
  }

  static enableScroll(enable = true) {
    const documentElClass = 'smooth-scroll'
    const scrollAttribute = "aria-scroll-disabled"
    if (enable) {
      if (!document.body.hasAttribute(scrollAttribute)) return

      document.body.removeAttribute(scrollAttribute)

      const body = document.body;
      const scrollY = body.style.top;
      body.style.position = '';
      body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);

      setTimeout(() => {
        document.documentElement?.classList.add(documentElClass)
      }, 1000)
    } else {
      document.documentElement?.classList.remove(documentElClass)

      if (document.body.hasAttribute(scrollAttribute)) return

      document.body.setAttribute(scrollAttribute, true)
      const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
      const body = document.body;
      body.style.position = 'fixed';
      body.style.top = `-${scrollY}`;
    }
  }
}

