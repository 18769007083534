import { GoogleMapKey } from '../config';
var geocoder = require('google-geocoder');

var geo = geocoder({
    key: GoogleMapKey
});

export function getGeoInformation(address, cb) {
    geo.find(address, function (err, res) {
        cb(res)
    });
}