import React from "react";
import { TextStyle, Card } from "@shopify/polaris";

export const renderApptDetails = (
  formattedDate,
  handleBack
) => {
  return (
    <>
      <Card
        title="Service appointment"
        actions={[
          {
            content: (
              <span className="Polaris-Button not-small-screen appt-details">
                <span className="Polaris-Button__Content">
                  <span className="Polaris-Button__Text">Edit</span>
                </span>
              </span>
            ),
            onAction: () => { handleBack() }
          },
        ]}
        primaryFooterAction={{
          content: (
            <span className="Polaris-Button Polaris-Button--sizeSlim small-screen appt-details">
              <span className="Polaris-Button__Content">
                <span className="Polaris-Button__Text" onClick={() => {handleBack()}}>Edit</span>
              </span>
            </span>
          ),
        }}
      >
        <div className="Polaris-Card__Section serviceAppointmentTime">
          <TextStyle variation="subdued">{formattedDate}</TextStyle>
        </div>
      </Card>
    </>
  );
};
