import React, { useEffect } from "react";

import "./styles.scss";
import iconPhoneCall from "@src/assets/images/booking/phone-call-yellow.svg";
import iconStar from "@src/assets/images/booking/star-yellow.svg";

import { ProductCategorySelect } from "@src/components";
import { ProductListItem } from "./product-list-item";
import { ExpandableContainer } from "./expandable-container";

import { Product, Criteria, segmentClient } from "@src/core";

import { BookingFlowFrame, BookingFlowPage } from "@src/components";
import { useSearchParams } from "react-router-dom";

import { routes } from "../../../routes";
import { useNavigate } from "react-router-dom";
import { Spinner } from "@shopify/polaris";
import { useBooking } from "@src/core";
import { API_BASE_URL, AppBaseUrl } from "@src/config";

export function ChooseProductPage({}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { setTankType, setTypeformData } = useBooking();
  const urlToken = searchParams.get("url_token");
  const navigate = useNavigate();
  const [segmentIdentified, setSegmentIdentified] = React.useState(false);
  React.useEffect(() => {
    if (!urlToken) {
      location.href = AppBaseUrl;
    }
  }, []);

  const [criteria, setCriteria] = React.useState({
    id: null,
    homeType: "single_family",
    powerType: "gas",
    bathroomCoverage: 1,
    stairAccess: "no-stairs",
    relocation: "current",
  });

  React.useEffect(() => {
    if (!criteria || criteria.id == null || segmentIdentified) return;

    segmentClient.setAnonymousId(urlToken);
    segmentClient.identify({
      productCriteria: criteria,
      customerLead: {
        id: criteria.customerLead,
        urlToken,
      },
    });

    setSegmentIdentified(true);
  }, [urlToken, criteria]);

  const [products, setProducts] = React.useState(Product.fromJSONArray([]));
  const [isLoading, setIsLoading] = React.useState(true);
  const [isRefreshAvailable, setIsRefreshAvailable] = React.useState(true);

  const fetchData = async () => {
    let response = await fetch(
      `${API_BASE_URL}booking/product/selected/${urlToken}`
    );
    response = await response.json();

    const {
      product_catalog: productCatalog,
      product_criteria: productCriteria,
    } = response;

    if (productCriteria.tank_type == "tankless") {
      setTankType(0);
    } else {
      setTankType(1);
    }

    setTypeformData(
      urlToken,
      productCriteria.bathroom_coverage,
      productCriteria.power_type
    );

    setProducts(Product.fromJSONArray(productCatalog));
    setCriteria(Criteria.fromJSON(productCriteria));
    setIsRefreshAvailable(true);
  };

  const selectProduct = async (productId) => {
    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        url_token: urlToken,
        product_catalog_id: productId,
      }),
    };

    let response = await fetch(
      `${API_BASE_URL}booking/select_product/`,
      settings
    );
    response = await response.json();

    const routeTo =
      criteria.tankType == "tankless"
        ? routes.ScheduleTankless
        : routes.ScheduleTank;

    navigate(routeTo, {
      state: {
        urlToken,
      },
    });
  };

  useEffect(() => {
    fetchData()
      .then(() => {
        setIsLoading(false);
      })
      .catch(console.error);
  }, []);

  const updateProductCriteria = async () => {
    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(Criteria.toJSON(criteria)),
    };

    let response = await fetch(
      `${API_BASE_URL}booking/update_product_criteria/`,
      settings
    );

    if (response.status === 200) {
      setIsRefreshAvailable(false);
      fetchData()
        .then(() => {
          setIsRefreshAvailable(true);
        })
        .catch(console.error);
    }
  };

  useEffect(() => {
    if (criteria.id === null || !isRefreshAvailable) return;
    setIsRefreshAvailable(false);
    setTypeformData(urlToken, criteria.bathroomCoverage, criteria.powerType);

    updateProductCriteria(criteria).catch(console.error);
  }, [criteria]);

  const chooseProductContent = (
    <div id="parent" className="choose-product">
      <div className="choose-product-wrapper">
        {isLoading && (
          <section className="product-isloading">
            <Spinner accessibilityLabel="Spinner example" size="large" />
          </section>
        )}
        {!isLoading && (
          <>
            <section className="category-select">
              <ProductCategorySelect
                criteria={criteria}
                onChange={(newCriteriaValues) => {
                  setCriteria(newCriteriaValues);
                }}
              />
            </section>
            {!isRefreshAvailable && (
              <section className="product-isloading">
                <Spinner accessibilityLabel="Spinner example" size="large" />
              </section>
            )}
            {isRefreshAvailable && (
              <section className="product-list">
                {products.length > 0 &&
                  products.map((product, index) => (
                    <ProductListItem
                      key={index}
                      product={product}
                      selectProduct={selectProduct}
                    />
                  ))}
                {products.length == 0 && (
                  <div className="no-product">
                    <div className="no-product-title">
                      No products match current option selections
                    </div>
                    <div className="no-product-description">
                      Change your current option selections to view available
                      products
                    </div>
                  </div>
                )}
              </section>
            )}
          </>
        )}

        <div className="call-us">
          <section>
            <img src={iconPhoneCall} alt="" />
            <h2>Want other options?</h2>
            <div>
              Call us - we're here to help{" "}
              <a href="tel:949822-8229">(949) 822-8229</a>
            </div>
          </section>
        </div>
        <section className="customers-love-us">
          <h2>Customers love us</h2>
          <div className="description">
            <img src={iconStar} alt="" />
            5.0 out of 5.0 stars from 25 reviews
          </div>
          <div
            className="embedsocial-reviews"
            data-ref="3043a5ff74968ab45d4e206b8cfab0765e7075a0"
          ></div>
        </section>
        <div className="frequently-asked-questions">
          <section>
            <h2>Frequently Asked Questions</h2>
            <div className="description">
              Please check this section if you have any questions, if you still
              have questions, email us{" "}
              <a href="mailto:care@homebreeze.com">care@homebreeze.com</a> we
              happy to assist you
            </div>
            <div className="question-list">
              <ExpandableContainer className="question-item">
                <div className="title">
                  What's included with my installation?
                </div>
                <div className="description">
                  Everything you need to get your new water heater installed:
                  <br></br>
                  <br></br>
                  <ul>
                    <li>A brand new water heater</li>
                    <li>Delivery of your new water heater</li>
                    <li>
                      Professional installation from one of our licensed
                      plumbers
                    </li>
                    <li>
                      All connectors, elements, and other parts needed for
                      installation
                    </li>
                    <li>
                      Free draining, hauling, and disposal of your old water
                      heater
                    </li>
                    <li>Assistance filing for local permits, when needed</li>
                  </ul>
                </div>
              </ExpandableContainer>
              <ExpandableContainer className="question-item">
                <div className="title">
                  What's not included with my installation?
                </div>
                <div className="description">
                  Rarely, we'll uncover a non-related issue that we recommend
                  fixing (for example, rotted drywall from leaks or dangerous
                  venting), but any fixes will be discussed in advance.
                </div>
              </ExpandableContainer>
              <ExpandableContainer className="question-item">
                <div className="title">How does payment work?</div>
                <div className="description">
                  HomeBreeze doesn't take upfront payments. You only pay after
                  the installation, and you can pay with credit or debit cards.
                </div>
              </ExpandableContainer>
              <ExpandableContainer className="question-item">
                <div className="title">How does your price guarantee work?</div>
                <div className="description">
                  We’re so confident we offer the best price that if we can’t
                  beat a like-for-like quote, we’ll give you a $100 Amazon gift
                  card.<br></br>
                  <br></br>
                  Most plumbers won’t share prices without coming onsite. Often,
                  once work starts, your price will increase because of “extra”
                  parts or “unexpected” work.<br></br>
                  <br></br>
                  HomeBreeze offers all-inclusive prices. We don’t have any
                  added charges. We honor our online prices, even if it means
                  taking a loss.
                </div>
              </ExpandableContainer>
              <ExpandableContainer className="question-item">
                <div className="title">
                  I have another question. Who can I call?
                </div>
                <div className="description">
                  Us! We keep our phones on.{" "}
                  <a href="tel:9498228229">(949) 822-8229</a>
                </div>
              </ExpandableContainer>
            </div>
          </section>
        </div>
      </div>
    </div>
  );

  const chooseProductPage = (
    <BookingFlowPage title="Let's choose a product." titleOnly>
      {chooseProductContent}
    </BookingFlowPage>
  );

  return <BookingFlowFrame>{chooseProductPage}</BookingFlowFrame>;
}
