import React from "react";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { useLocation, useNavigate, createSearchParams } from "react-router-dom";
import { Spinner } from "@shopify/polaris";
import { BookingFlowFrame, BookingFlowPage } from "@src/components";
import { API_BASE_URL } from "@src/config";
import "./styles.scss";

export function SchedulePage({ calendlyUrl }) {
  let location = useLocation();
  const navigate = useNavigate();

  const { urlToken } = location.state;
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    (async () => {
      const settings = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          url_token: urlToken,
        }),
      };
      await fetch(API_BASE_URL + `booking/calendly/reschedule/`, settings);
    })();
  }, []);

  const saveCalendlyData = async (calendlyData) => {
    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        url_token: urlToken,
        calendly_data: calendlyData,
      }),
    };

    let response = await fetch(
      `${API_BASE_URL}booking/calendly/`,
      settings
    );
    response = await response.json();
  };

  const calendlyEventHandler = (e) => {
    setIsLoading(false)
    const calendlyData = e.data;
    if (calendlyData.event === "calendly.event_scheduled") {
      saveCalendlyData(calendlyData).catch(console.error);
    }
  };

  const handleBack = () => {
    navigate({
      pathname: "/choose-product/",
      search: createSearchParams({
        url_token: urlToken,
      }).toString(),
    });
  }

  useCalendlyEventListener({
    onDateAndTimeSelected: calendlyEventHandler,
    onEventScheduled: calendlyEventHandler,
    onEventTypeViewed: calendlyEventHandler,
    onProfilePageViewed: calendlyEventHandler,
  });

  const schedulePageContent = (
    <div className="schedule-tankless">
      {isLoading && (
        <section className="calendar-loading">
          <Spinner accessibilityLabel="Spinner example" size="large" />
        </section>
      )}
      <InlineWidget
        url={calendlyUrl}
        styles={{ position: "relative", height: "650px" }}
      />
    </div>
  );

  const bookingFlowPage = (
    <BookingFlowPage title="Pick installation date" handleBack={handleBack}>
      {schedulePageContent}
    </BookingFlowPage>
  );

  return <BookingFlowFrame>{bookingFlowPage}</BookingFlowFrame>;
}
