import React from 'react'

import './styles.scss'
import iconCaret from '@src/assets/images/booking/caret-up-16px-22252D.svg'

export function ExpandableContainer({
  className = "",
  children,
}) {
  const [isExpanded, setExpanded] = React.useState(true)

  return (
    <div className={`${className} expandable-container ${isExpanded && 'expanded'}`}>
      <button className="btn-expand" onClick={() => setExpanded(!isExpanded)}>
        <img src={iconCaret} alt="" />
      </button>
      {children}
    </div>
  )
}
