import React from "react";
import { Card, IndexTable, TextStyle } from "@shopify/polaris";
import "./styles.scss";

export function PartListTable({ partList = [] }) {
  const heading = [{ title: "Part", align: "right" }, { title: "Quantity" }];

  const rowMarkup = partList.map(({ item, quantity }, index) => (
    <IndexTable.Row key={index} position={index}>
      <IndexTable.Cell>
        <TextStyle variation="strong">{item}</TextStyle>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <TextStyle variation="strong">
          <p className="align-right">{quantity}</p>
        </TextStyle>
      </IndexTable.Cell>
    </IndexTable.Row>
  ));

  return (
    <Card>
      <IndexTable
        itemCount={partList.length}
        headings={heading}
        selectable={false}
      >
        {rowMarkup}
      </IndexTable>
    </Card>
  );
}
