import React, { useState, useCallback, useEffect } from "react";
import { Stack, Layout, Modal, ChoiceList, Toast, Spinner } from "@shopify/polaris";
import {
  useSearchParams,
  useNavigate,
  createSearchParams,
} from "react-router-dom";
import { renderApptDetails } from "./appointment";
import { renderContactInfo } from "./customer";
import { renderProductSelection } from "./selectedProduct";
import { SelectedProduct, Customer, Appointment } from "@src/core";
import { API_BASE_URL } from "@src/config";
import { useBooking } from "@src/core";
import { routes } from "../../../routes";

import {
  getGeoInformation,
  validateEmail,
  formatJobDate,
  validatePhoneNumber,
  getRawPhoneNumber,
} from "@src/utils";

import {
  PrimaryButton,
  BookingFlowFrame,
  BookingFlowPage,
} from "@src/components";

import "./styles.scss";

const initialFieldValues = {
  firstName: {
    value: "",
    errorMsg: "",
    fieldFormalName: "First name",
    isRequired: true,
  },
  lastName: {
    value: "",
    errorMsg: "",
    fieldFormalName: "Last name",
    isRequired: true,
  },
  email: {
    value: "",
    errorMsg: "",
    fieldFormalName: "Email",
    isRequired: true,
  },
  phone: {
    value: "",
    errorMsg: "",
    fieldFormalName: "Phone number",
    isRequired: true,
  },
  address: {
    value: "",
    errorMsg: "",
    fieldFormalName: "Address",
    isRequired: true,
  },
  aptUnit: {
    value: "",
    errorMsg: "",
    fieldFormalName: "Apt/Unit",
    isRequired: false,
  },
  city: {
    value: "",
    errorMsg: "",
    fieldFormalName: "City",
    isRequired: true,
  },
  zipCode: {
    value: "",
    errorMsg: "",
    fieldFormalName: "Postal Code",
    isRequired: true,
  },
  gateCode: {
    value: "",
    errorMsg: "",
    fieldFormalName: "Gate code",
    isRequired: false,
  },
};

export function SummaryPage({}) {
  const navigate = useNavigate();

  const { setTankType, setTypeformData } = useBooking();
  const [isLoading, setIsLoading] = useState(true);

  // Error Toast
  const [toast, setToast] = useState({
    isActive: false,
    message: "",
    isError: false,
  });
  const toggleToastActive = useCallback(
    () => setToast({ ...toast, isActive: !toast.isActive }),
    [toast]
  );


  const [calendlySearchParams, setCalendlySearchParams] = useSearchParams();
  const [customer, setCustomer] = useState(Customer.fromJSON({}));
  const [appointment, setAppointment] = useState(Appointment.fromJSON({}));

  const [geoCodedAddress, setGeoCodedAddress] = useState(null);
  const [addressModalActive, setAddressModalActive] = useState(false);
  const [originalAddress, setOriginalAddress] = useState("");
  const [selectedAddress, setSelectedAddress] = useState([-1]);

  // Customer details
  const [isAddressInvalid, setIsAddressInvalid] = useState(false);
  const [inputFieldValues, setFormFieldValues] = useState(initialFieldValues);

  // Appointment details
  const [formattedDate, setFormattedDate] = useState("Loading Service Date...");

  // Selected Product details
  const [isRebateOpen, setIsRebateOpen] = React.useState(false);
  const handleRebateToggle = React.useCallback(() => setIsRebateOpen((open) => !open), []);
  const [selectedProduct, setSelectedProduct] = useState(
    SelectedProduct.fromJSON({})
  );

  /**
   * Initialize data part
   */

  const fetchCalendlyData = async () => {
    const invitee_uuid = calendlySearchParams.get("invitee_uuid");
    let response = await fetch(`${API_BASE_URL}booking/calendly_invitee/${invitee_uuid}`);
    const customerJSON = await response.json();

    const { selected_product: selectedProduct } = customerJSON.lead;

    let customerObj = Customer.fromJSON(customerJSON);
    customerObj.zipCode =
      customerObj.lead.calendlyInvitee.event.location.location;

    setCustomer(customerObj);
    setSelectedProduct(SelectedProduct.fromJSON(selectedProduct));
    setAppointment({
      date: customerObj.lead.calendlyInvitee.event.startTime,
    });
    if (customerObj.lead.productCriteria.tankType == "tankless") {
      setTankType(0);
    } else {
      setTankType(1);
    }
    setTypeformData(customerObj.lead.urlToken, customerObj.lead.productCriteria.bathroomCoverage, customerObj.lead.productCriteria.powerType)
    setIsLoading(false)
  };

  useEffect(() => {
    fetchCalendlyData().catch(console.error);
  }, []);

  useEffect(() => {
    if (
      appointment.date !== undefined &&
      inputFieldValues.zipCode.value == ""
    ) {
      setFormattedDate(formatJobDate(appointment.date));

      inputFieldValues.zipCode.value = customer.zipCode;
      inputFieldValues.firstName.value = customer.firstName;
      inputFieldValues.lastName.value = customer.lastName;
      inputFieldValues.email.value = customer.email;
      setFormFieldValues(initialFieldValues);
    }
  }, [customer, appointment]);

  /**
   * Event Handlers for product detail
   */
  // Event Handler for Change button from product detail
  const onClickSelectedProduct = () => {
    navigate({
      pathname: "/choose-product/",
      search: createSearchParams({
        url_token: customer.lead.urlToken,
      }).toString(),
    });
  };

  /**
   * Event handlers & funtions for Contact & home information
   */

  const handleInputFieldsChanges = useCallback(
    (field, value) => {
      if (
        field === "address" ||
        field === "aptUnit" ||
        field === "city" ||
        field === "zipCode"
      ) {
        setIsAddressInvalid(false);
      }
      setFormFieldValues({
        ...inputFieldValues,
        [field]: {
          value: value,
          errorMsg: "",
          isRequired: inputFieldValues[field].isRequired,
          fieldFormalName: inputFieldValues[field].fieldFormalName,
        },
      });
    },
    [inputFieldValues]
  );

  const handleInputFieldsBlur = useCallback(
    (field, evt) => {
      let value = evt.target.value;
      if (value == "") return;

      setCustomer({
        ...customer,
        [field]: value,
      });
    },
    [customer]
  );

  const onClickConfirm = () => {
    var inputFieldValuesTemp = inputFieldValues;
    var isError = false;
    Object.keys(inputFieldValuesTemp).map((field, index) => {
      if (
        field === "phone" &&
        inputFieldValuesTemp[field].value !== "" &&
        !validatePhoneNumber(inputFieldValuesTemp[field].value)
      ) {
        isError = true;
        inputFieldValuesTemp = {
          ...inputFieldValuesTemp,
          [field]: {
            value: inputFieldValuesTemp[field].value,
            errorMsg: "Phone number format is incorrect",
            isRequired: inputFieldValuesTemp[field].isRequired,
            fieldFormalName: inputFieldValuesTemp[field].fieldFormalName,
          },
        };
      } else if (
        field === "email" &&
        !validateEmail(inputFieldValuesTemp[field].value)
      ) {
        isError = true;
        inputFieldValuesTemp = {
          ...inputFieldValuesTemp,
          [field]: {
            value: inputFieldValuesTemp[field].value,
            errorMsg: "Email format is incorrect",
            isRequired: inputFieldValuesTemp[field].isRequired,
            fieldFormalName: inputFieldValuesTemp[field].fieldFormalName,
          },
        };
      }
      if (
        inputFieldValuesTemp[field].isRequired &&
        inputFieldValuesTemp[field].value === ""
      ) {
        isError = true;
        inputFieldValuesTemp = {
          ...inputFieldValuesTemp,
          [field]: {
            value: inputFieldValuesTemp[field].value,
            errorMsg:
              inputFieldValuesTemp[field].fieldFormalName + " is required",
            isRequired: inputFieldValuesTemp[field].isRequired,
            fieldFormalName: inputFieldValuesTemp[field].fieldFormalName,
          },
        };
      }
    });
    setFormFieldValues(inputFieldValuesTemp);
    if (!isError) {
      const originalAddressTemp = inputFieldValues.aptUnit.value
        ? inputFieldValues.address.value +
          ", " +
          inputFieldValues.aptUnit.value +
          ", " +
          inputFieldValues.city.value +
          ", " +
          inputFieldValues.zipCode.value
        : inputFieldValues.address.value +
          ", " +
          inputFieldValues.city.value +
          ", " +
          inputFieldValues.zipCode.value;
      setOriginalAddress(originalAddressTemp);
      // Add country code to get only USA address
      getGeoInformation(originalAddressTemp + "USA", setGeoCodedAddress);
    }
  };

  useEffect(() => {
    if (geoCodedAddress === null) return;
    if (geoCodedAddress.length == 0) {
      setIsAddressInvalid(true);
    } else {
      setAddressModalActive(true);
      setSelectedAddress([0]);
    }
  }, [geoCodedAddress]);

  const handleSubmit = async () => {
    var addressForSubmit;
    if (selectedAddress[0] == -1) {
      addressForSubmit = originalAddress;
    } else {
      addressForSubmit = geoCodedAddress[selectedAddress[0]].formatted_address;
    }

    const formattedPhone = getRawPhoneNumber(customer.phone);

    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        customer: {
          ...Customer.toJSON(customer),
          address: addressForSubmit,
          phone: formattedPhone,
        },
        appointment: {
          date: customer.lead.calendlyInvitee.event.startTime,
        },
        selected_product: SelectedProduct.toJSON(customer.lead.selectedProduct),
      }),
    };

    let response = await fetch(API_BASE_URL + `booking/customer_order/`, settings);
    if (response.status != 200) {
      setToast({
        isActive: true,
        message: "There was an issue completing booking",
        isError: true,
      });
      return
    }
    navigate({
      pathname: "/success/",
      search: createSearchParams({
        type: customer.lead.productCriteria.tankType,
        firstname: customer.firstName,
        phone: formattedPhone,
      }).toString(),
    });
  };

  const handleBack = () => {
    const routeTo =
    customer.lead.productCriteria.tankType == "tankless"
      ? routes.ScheduleTankless
        : routes.ScheduleTank;
    const urlToken = customer.lead.urlToken;
    navigate(routeTo, {
      state: {
        urlToken,
      },
    });
  }

  const toastMarkup = toast.isActive ? (
    <Toast
      content={toast.message}
      error={toast.isError}
      onDismiss={toggleToastActive}
    />
  ) : null;

  function renderTermsOfService(isMobile) {
    const className = isMobile
      ? "caption-text small-screen"
      : "caption-text not-small-screen";
    return (
      <div className={className}>
        By scheduling, you agree to read and accept our{" "}
        <a href="">terms of service.</a> <br />
        Have questions now? Call us at{" "}
        <a href="tel:(949) 822-8229">(949) 822-8229</a>
      </div>
    );
  }

  /**
   * Addtional modal to check addres
   */

  const addressActiveModal = (
    <Modal
      open={addressModalActive}
      title="Verify address"
      onClose={() => {
        setAddressModalActive(false);
      }}
      primaryAction={{
        content: "Use selected address",
        onAction: () => {
          handleSubmit();
          setAddressModalActive(false);
        },
      }}
      secondaryActions={{
        content: "Edit",
        onAction: () => {
          setAddressModalActive(false);
        },
      }}
    >
      <Modal.Section>
        <Layout>
          <Layout.Section>
            <ChoiceList
              title="Suggested address"
              choices={geoCodedAddress?.map((geo, index) => {
                return {
                  label: geo.formatted_address,
                  value: index,
                };
              })}
              selected={selectedAddress}
              onChange={(value) => {
                setSelectedAddress(value);
              }}
            />
          </Layout.Section>
          <Layout.Section>
            <ChoiceList
              title="Original address"
              choices={[{ label: originalAddress, value: -1 }]}
              selected={selectedAddress}
              onChange={(value) => {
                setSelectedAddress([-1]);
              }}
            />
          </Layout.Section>
        </Layout>
      </Modal.Section>
    </Modal>
  );

  function renderPageContent() {
    return isLoading ? (
      <div className="page-isloading">
        <Spinner accessibilityLabel="Spinner example" size="large" />
      </div> ) : (
      <div className={"summary-page-container"}>
        <Layout>
          <Layout.Section>
            <Stack vertical spacing="loose" distribution="trailing">
              <Stack.Item>{renderApptDetails(formattedDate, handleBack)}</Stack.Item>
              <Stack.Item>
                {renderContactInfo(
                  isAddressInvalid,
                  inputFieldValues,
                  handleInputFieldsChanges,
                  handleInputFieldsBlur
                )}
              </Stack.Item>
            </Stack>
          </Layout.Section>
          <Layout.Section secondary>
            <Stack vertical spacing="loose" distribution="trailing">
              <Stack.Item>
                  {renderProductSelection(
                    isRebateOpen,
                    handleRebateToggle,
                    onClickSelectedProduct,
                    selectedProduct
                )}
              </Stack.Item>
              <Stack.Item>{renderTermsOfService()}</Stack.Item>
            </Stack>
          </Layout.Section>
        </Layout>
        <Layout>
          <Layout.Section>
            <Stack vertical spacing="loose">
              <Stack.Item>
                <div className="primaryActionWrapper">
                  <PrimaryButton size="large" onClick={onClickConfirm}>
                    Confirm
                  </PrimaryButton>
                </div>
              </Stack.Item>
              <Stack.Item>
                <div>{renderTermsOfService(true)}</div>
              </Stack.Item>
            </Stack>
          </Layout.Section>
        </Layout>
        {addressActiveModal}
      </div>
    );
  }

  function renderPage() {
    return (
      <BookingFlowPage
        title="Let’s finalize your details"
        handleBack={handleBack}
      >
        {renderPageContent()}
        {toastMarkup}
      </BookingFlowPage>
    );
  }

  return <BookingFlowFrame>{renderPage()}</BookingFlowFrame>;
}
