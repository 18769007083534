import React from "react";
import { Stack, Card, Collapsible, Icon } from "@shopify/polaris";
import { SecondaryButton } from "@src/components";
import { TickSmallMinor } from "@shopify/polaris-icons";
import { CaretDownMinor, CaretUpMinor } from "@shopify/polaris-icons";
import { formatCurrency } from "@src/utils";

export const renderProductSelection = (
  isRebateOpen = false,
  handleRebateToggle = () => {},
  onClickSelectedProduct = () => {},
  selectedProduct = {}
) => {

  const { productCatalog } = selectedProduct;
  const { product } = productCatalog;

  const tags = ["Heater & Parts", "Delivery", "Installation", "Disposal"];
  const productTankTypeCopy =
    product.tankType == "tankless"
      ? "Tankless Water Heater"
      : "Tank Water Heater";

  const finalPrices = {
    installationCost: formatCurrency(productCatalog.installationPrice),
    rebates: formatCurrency(productCatalog.totalRebates),
    socalGasRebates: formatCurrency(productCatalog.socalGasRebates),
    federalTaxCredit: formatCurrency(productCatalog.federalTaxCredit),
    priceAfterRebates: formatCurrency(productCatalog.finalPrice),
  };

  const PlainTag = (props) => {
    return (
      <div className="plain-tag flex items-center ">
        <Icon color={"success"} source={TickSmallMinor} />
        <span>{props.children}</span>
      </div>
    );
  };

  return (
    <div className="selected-product">
      <Stack horizontal distribution="equalSpacing">
        <Stack.Item>
          <div className="product-name">{product.title}</div>
          <div className="product-type">{productTankTypeCopy}</div>
        </Stack.Item>

        <SecondaryButton size="slim" onClick={onClickSelectedProduct}>
          Change
        </SecondaryButton>
      </Stack>

      <Stack horizontal distribution="equalSpacing" wrap={false}>
        <div>Due before installation</div>
        <div className="product-price">$0.00</div>
      </Stack>

      <Stack horizontal distribution="equalSpacing" wrap={false}>
        <div>Due upon installation</div>
        <div className="product-price">{finalPrices.installationCost}</div>
      </Stack>

      {productCatalog.totalRebates != 0 && (
        <>
          <div className="sub-heading">After Installation</div>
          <Stack horizontal distribution="equalSpacing">
            <Stack.Item>
              <div className="summary-rebate" onClick={handleRebateToggle}>
                <u>Rebates &amp; credits</u>
                {isRebateOpen && (
                  <Icon source={ CaretUpMinor } color="base" />
                )}
                {!isRebateOpen && (
                  <Icon source={ CaretDownMinor } color="base" />
                )}
              </div>
            </Stack.Item>
            <Stack.Item>
              <div className="product-price">-{finalPrices.rebates}</div>
            </Stack.Item>
          </Stack>

          {isRebateOpen && (
            <>
              {productCatalog.socalGasRebates != 0 && (
                <Stack horizontal distribution="equalSpacing">
                  <span>Socal gas rebate</span>
                  <span>-{finalPrices.socalGasRebates}</span>
                </Stack>
              )}
              {productCatalog.federalTaxCredit != 0 && (
                <Stack horizontal distribution="equalSpacing">
                  <span>Federal tax credit</span>
                  <span>-{finalPrices.federalTaxCredit}</span>
                </Stack>
              )}
            </>
          )}

          <Stack horizontal distribution="equalSpacing">
            <span>Price after rebates &amp; credits</span>
            <div className="product-price">{finalPrices.priceAfterRebates}</div>
          </Stack>
        </>
      )}

      {productCatalog.totalRebates == 0 && (
        <Stack horizontal distribution="equalSpacing" wrap={false}>
          <div>Total</div>
          <div className="product-price">{finalPrices.installationCost}</div>
        </Stack>
      )}

      <div className="sub-heading sub-heading-include">Included in the price</div>
      <Stack horizontal>
        {tags.map((item, idx) => (
          <PlainTag key={idx}>{item}</PlainTag>
        ))}
      </Stack>
    </div>
  );
};
